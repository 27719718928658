import React from "react"
// import Img from "gatsby-image"
import {  graphql } from "gatsby"
import style from "./services.module.css"
import styled from "./page.module.css"
import Navbar from "../components/Navbar"
import Footer from "../components/footer"

 

const Security= ({data}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return ( 
  <>
  <Navbar />
    <header>
      <div className={style.p}>
      <div className={style.heading}>"Let's face it, this is the 'bane'of all web based product development. Let Prueba ease your pain."</div>
      </div>
      </header>
      <div className={styled.content} >
      <h2>{frontmatter.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: html }}/> 
    </div>
    <Footer />
      </>
      
  )
}
export const query = graphql`
query security {
    markdownRemark(fileAbsolutePath: {regex: "/SecurityTesting.md/"}) {
        html
        frontmatter {
          title
        }
      }
    }
    `

export default Security